<template>
  <el-drawer
    :visible.sync="drawer"
    direction="rtl"
    destroy-on-close
    custom-class="Receipt"
    :append-to-body="true"
    size="100%"
  >
    <div class="container" v-loading="loading">
      <h3>{{ entity.CompanyName }}</h3>
      <div class="cell"><span>单号:</span>{{ entity.Id }}</div>
      <div class="cell">
        <span>日期:</span
        >{{ entity.CreateTime ? entity.CreateTime.substring(0, 19) : "" }}
      </div>
      <div class="cell"><span>客户:</span>{{ entity.cCusName }}</div>
      <div
        class="tableRow"
        style="border-bottom: 1px dashed #ccc; margin-top: 10px; line-height: 2"
      >
        <span class="tableIndex"></span>
        <span class="tableCol left">品名/编码</span>
        <span class="tableCol">数量</span>
        <!-- <span class="tableCol">单位</span> -->
        <span class="tableCol">单价</span>
        <span class="tableCol">小计</span>
      </div>
      <div style="padding: 10px 0">
        <div v-for="(item, index) in data" :key="index">
          <div class="tableRow">
            <span class="tableIndex">{{ index + 1 }}</span>
            <span class="tableCol left">{{ item.InventName }}</span>
            <span class="tableCol">{{ item.Quantity }}</span>
            <span class="tableCol">{{
              item.Price ? item.Price.toFixed(2) : ""
            }}</span>
            <span class="tableCol">{{
              item.Money ? item.Money.toFixed(2) : ""
            }}</span>
          </div>
          <div class="tableRow">
            <span class="tableIndex"></span>
            <span class="tableCol left">{{ item.cInvCode }}</span>
            <span class="tableCol">{{ item.GroupName }}</span>
            <span class="tableCol col2">规格: {{ item.cInvStd }}</span>
          </div>
        </div>
      </div>
      <div
        class="tableRow"
        style="border-top: 1px dashed #ccc; line-height: 2; margin-bottom: 10px"
      >
        <span class="tableIndex"></span>
        <span class="tableCol left">合计</span>
        <span class="tableCol">{{ entity.TotalQuantity }}</span>
        <span class="tableCol"></span>
        <span class="tableCol">{{
          entity.TotalSum ? entity.TotalSum.toFixed(2) : ""
        }}</span>
      </div>
      <div class="cell">
        <span v-if="entity.Type == 0" style="width: 150px"
          >现收:
          {{ entity.ReceiveSum ? entity.ReceiveSum.toFixed(2) : 0 }}</span
        >
        <span v-if="entity.Type == 1" style="width: 150px"
          >赊货:
          {{ entity.PendingSum ? entity.PendingSum.toFixed(2) : 0 }}</span
        >
      </div>
      <!-- <div style="text-align: center; padding-top: 30px">
        <span style="display: block">扫码查看电子小票</span>
        <vue-qr
          class="box"
          :text="codeValue"
          :logoScale="0.2"
          :size="200"
        ></vue-qr>
      </div> -->
    </div>
  </el-drawer>
</template>
  
<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      data: [],
      codeValue: "",
      entity: {},
      href: "",
      loading: false,
      drawer: false,
    };
  },
  // mounted() {
  //     this.codeValue = location.href;
  //     this.getTheData(this.$route.query.Id);
  //     if (this.$route.query.billId) this.getFormData(this.$route.query.billId);
  //     this.getShareInfo();
  // },
  methods: {
    openForm(Id, share) {
      this.drawer = true;
      this.codeValue = location.href;
      this.data = [];
      this.entity = {};
      this.getTheData(Id);
      // if (this.$route.query.billId) this.getFormData(this.$route.query.billId);
      if (share) return;
      this.getShareInfo();
    },
    // getFormData(id) {
    //     this.$http.post("/BO/BillList/GetBillDetail", { id }).then((res) => {
    //         if (res.Success) {
    //             this.$http
    //                 .post("/BO/BillList/GetBillLink", {
    //                     id: res.Data.QrCode,
    //                 })
    //                 .then((result) => {
    //                     if (result.Success) {
    //                         this.href = result.Data;
    //                     }
    //                 });
    //         }
    //     });
    // },
    getTheData(Id) {
      this.loading = true;
      this.$http.post("/BO/BIlling/GetBllingDetail?Id=" + Id).then((res) => {
        this.loading = false;
        if (res.Success) {
          this.data = res.Data.detalist;
          this.entity = res.Data.maindata;
        } else this.$message.error(res.Msg);
      });
    },
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?AppType=AppShq&url=" + url
      );
      let arr;
      if (ticketData.Data.length !== 0) {
        arr = ticketData.Data.split(",");
      }

      const config = {
        appId: "wx2ba9027e237ff635",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: "赊销订货",
          desc: this.entity.CompanyName + "的电子小票",
          link: location.href,
          imgUrl: "https://hzq.yoojet.com/wx.png",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
  },
};
</script>
  
<style lang="less" scoped>
.fixBtn {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.Receipt {
  .container {
    margin: 0 auto;
    color: #000;
    min-width: 216px;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px;

    h3 {
      text-align: center;
    }

    .cell {
      display: flex;
      line-height: 2;

      span {
        width: 40px;
      }
    }

    .tableRow {
      font-size: 12px;
      display: flex;
      align-items: center;

      span {
        overflow: hidden;
      }

      .tableCol {
        text-align: right;
        width: 75px;
        overflow: hidden;
      }

      .left {
        text-align: left;
        flex: 1;
      }

      .tableIndex {
        width: 25px;
      }

      .col2 {
        width: 150px;
      }
    }
  }
}
</style>